<div class="footer">
  <div class="logo">
    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx':'https://www.mexicotoptours.com/'">
      <img src="assets/images/logo-b.webp" alt="">
    </a>
  </div>
  <div class="contact">
    <h4 class="title">{{'CONTACT_US' | translate}}</h4>
    <a href="tel:+52 998 195 6088" target="_blank">
      <p>+52 998 195 6088</p>
    </a>
    <p> {{'CONTACT_ADDRESS' | translate}}</p>
    <p>Cancún, México</p>
  </div>
  <div class="menu">
    <h4 class="title"> {{'MENU.MENU' | translate}}</h4>
    <ul>
      <li><a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx':'https://www.mexicotoptours.com/'"><span>{{'MENU.HOME'
            | translate}} </span></a></li>



      <li>
        <div class="submenu"><span><a style="color: inherit;"
              [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/excursiones':'https://www.mexicotoptours.com/trips'">
              {{'MENU.TRIP' | translate}}</a> <i (click)="showExcursions = !showExcursions"
              [ngClass]=" showExcursions ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></span>
          <ul class="submenu-lvl1" *ngIf="showExcursions">
            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/cancun-excursions':'https://www.mexicotoptours.com/cancun-excursions'">
                  {{'CANCUN-EXCURSIONS.ALL-EXCURSIONS' | translate}} </a> </span>

            </li>


            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/eco-parks':'https://www.mexicotoptours.com/eco-parks'">
                  {{'CANCUN-EXCURSIONS.ECO-PARKS.TITLE' | translate}} </a> <i
                  (click)="showCancunTours = !showCancunTours" [ngClass]=" showCancunTours ? 'activeMenu' : ''"
                  class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showCancunTours">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215':'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.1' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.2' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.3' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545':'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.4' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.5' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.6' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.7' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.8' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006':'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.9' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.10' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'">
                    <span>{{'CANCUN-EXCURSIONS.ECO-PARKS.11' | translate}}</span>
                  </a>
                </li>


              </ul>
            </li>
            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/family':'https://www.mexicotoptours.com/family'">
                  {{'CANCUN-EXCURSIONS.FAMILY.TITLE' | translate}}</a> <i (click)="showMexicoTours = !showMexicoTours"
                  [ngClass]=" showMexicoTours ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showMexicoTours">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.2' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229':'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.3' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.4' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448':'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.5' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.6' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.7' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.8' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.9' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545':'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.10' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.11' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.12' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.13' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.14' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.15' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006':'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.16' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.17' | translate}}</span>
                  </a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'">
                    <span>{{'CANCUN-EXCURSIONS.FAMILY.18' | translate}}</span>
                  </a>
                </li>


              </ul>
            </li>

            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/archeological':'https://www.mexicotoptours.com/archeological'">
                  {{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.TITLE' | translate}} </a> <i
                  (click)="showExcursions1 = !showExcursions1" [ngClass]=" showExcursions1 ? 'activeMenu' : ''"
                  class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showExcursions1">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229':'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'">
                    <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.1' | translate}}</span></a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563':'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'">
                    <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.2' | translate}}</span></a>

                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.3' | translate}}</span></a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'">
                    <span>{{'CANCUN-EXCURSIONS.ARCHEOLOGICAL.4' | translate}}c</span></a>
                </li>
              </ul>
            </li>


            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/sailing':'https://www.mexicotoptours.com/sailing'">
                  {{'CANCUN-EXCURSIONS.SAILING.TITLE' | translate}} </a> <i (click)="showExcursions2 = !showExcursions2"
                  [ngClass]=" showExcursions2 ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showExcursions2">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'">
                    <span>{{'CANCUN-EXCURSIONS.SAILING.1' | translate}}</span></a>
                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'">
                    <span>{{'CANCUN-EXCURSIONS.SAILING.2' | translate}}</span></a>

                </li>
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'">
                    <span>{{'CANCUN-EXCURSIONS.SAILING.3' | translate}}</span></a>
                </li>

              </ul>
            </li>


            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/water-activities':'https://www.mexicotoptours.com/water-activities'">
                  {{'CANCUN-EXCURSIONS.WATER.TITLE' | translate}} </a> <i (click)="showExcursions3 = !showExcursions3"
                  [ngClass]=" showExcursions3 ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showExcursions3">
                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274':'https://www.mexicotoptours.com/tienda/CARIBBEAN-SUNSET-p469029274'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.1' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174':'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.2' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448':'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.3' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861':'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.4' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238':'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.5' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637':'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.6' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738':'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.7' | translate}}8</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190':'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.8' | translate}}</span></a>
                </li>
                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018':'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.9' | translate}}</span></a>
                </li>

                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272':'https://www.mexicotoptours.com/tienda/XEL-HA-ALL-INCLUSIVE-p222027272'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.10' | translate}}</span></a>
                </li>

                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432':'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.11' | translate}}</span></a>
                </li>

                <li>

                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778':'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.12' | translate}}</span></a>
                </li>

                <li>
                  <a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329':'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'">
                    <span>{{'CANCUN-EXCURSIONS.WATER.13' | translate}}</span></a>
                </li>

              </ul>
            </li>


            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/adventure':'https://www.mexicotoptours.com/adventure'">
                  {{'CANCUN-EXCURSIONS.ADVENTURE.TITLE' | translate}} </a> <i
                  (click)="showExcursions4 = !showExcursions4" [ngClass]=" showExcursions4 ? 'activeMenu' : ''"
                  class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showExcursions4">
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215' : 'https://www.mexicotoptours.com/tienda/ATV-CENOTE-p222022215'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.1' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174' : 'https://www.mexicotoptours.com/tienda/ISLA-MUJERES-p222022174'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.2' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448' : 'https://www.mexicotoptours.com/tienda/JUNGLE-TOUR-p222064448'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.3' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861' : 'https://www.mexicotoptours.com/tienda/COZUMEL-SNORKEL-&-EL-CIELO-p554714861'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.4' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563' : 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.5' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238' : 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.6' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637' : 'https://www.mexicotoptours.com/tienda/RIO-SECRETO-p467742637'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.7' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545' : 'https://www.mexicotoptours.com/tienda/XAVAGE-BASIC-p533150545'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.8' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432' : 'https://www.mexicotoptours.com/tienda/XENOTES-p467504432'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.9' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778' : 'https://www.mexicotoptours.com/tienda/XPLOR-p467759778'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.10' | translate }}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329' : 'https://www.mexicotoptours.com/tienda/XPLOR-FUEGO-p531703329'">
                    <span> {{'CANCUN-EXCURSIONS.ADVENTURE.11' | translate }}</span></a></li>
              </ul>
            </li>

            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/cultural':'https://www.mexicotoptours.com/cultural'">
                  {{'CANCUN-EXCURSIONS.CULTURAL.TITLE' | translate}} </a> <i
                  (click)="showExcursions5 = !showExcursions5" [ngClass]=" showExcursions5 ? 'activeMenu' : ''"
                  class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showExcursions5">
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229' : 'https://www.mexicotoptours.com/tienda/CHICHEN-ITZA-BASIC-p676044229'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.1' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563' : 'https://www.mexicotoptours.com/tienda/TULUM-EXPRESS-&-PLAYA-DEL-CARMEN-p531943563'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.2' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238' : 'https://www.mexicotoptours.com/tienda/TULUM-&-XEL-HA-p222022238'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.3' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738' : 'https://www.mexicotoptours.com/tienda/XCARET-BASIC-p674227738'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.4' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190' : 'https://www.mexicotoptours.com/tienda/XCARET-PLUS-p222022190'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.5' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018' : 'https://www.mexicotoptours.com/tienda/XICHEN-CLASSIC-p360423018'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.6' | translate }} </span> </a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006' : 'https://www.mexicotoptours.com/tienda/XOXIMILCO-p467374006'">
                    <span> {{'CANCUN-EXCURSIONS.CULTURAL.7' | translate }} </span> </a></li>
              </ul>
            </li>


            <li class="tour"><span><a style="color: inherit;"
              [href]="lang == 'es' ? 'https://www.mexicotoptours.com/nightlife':'https://www.mexicotoptours.com/nightlife'">
              {{'CANCUN-EXCURSIONS.NIGHTLIFE.TITLE' | translate}} </a> <i
              (click)="showExcursions6 = !showExcursions6" [ngClass]=" showExcursions6 ? 'activeMenu' : ''"
              class="fa fa-chevron-down"></i></span>
          <ul class="submenu-lvl2" *ngIf="showExcursions6">
            <li>
              <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-REGULAR-PREMIUM-p222118973':'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-REGULAR-PREMIUM-p222118973'"
              > <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.1' | translate}} </span></a>
           </li>
            <li><a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-GOLD-MEMBER-FRONT-ROW-p674203455':'https://www.mexicotoptours.com/tienda/COCO-BONGO-CANCUN-GOLD-MEMBER-FRONT-ROW-p674203455'"
              > <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.2' | translate}} </span></a>
            </li>
            <li> <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/tienda/THE-BONGO-TOUR-p222134249':'https://www.mexicotoptours.com/tienda/THE-BONGO-TOUR-p222134249'"
              > <span>{{'CANCUN-EXCURSIONS.NIGHTLIFE.3' | translate}} </span></a>
            </li>


          </ul>
        </li>


          </ul>
        </div>
      </li>
















      <!-- Circuits -->

      <li>
        <div class="submenu"><span><a style="color: inherit;"
              [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/circuitos':'https://www.mexicotoptours.com/tours'">{{'MENU.TOURS'
              | translate}}</a> <i (click)="showTripsSubmenu = !showTripsSubmenu"
              [ngClass]=" showTripsSubmenu ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></span>
          <ul class="submenu-lvl1" *ngIf="showTripsSubmenu">
            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/cancun-circuitos':'https://www.mexicotoptours.com/cancun-tours'">{{'TOURS.CANCUN_TOURS'
                  | translate}} </a> <i (click)="showCancunTours = !showCancunTours"
                  [ngClass]=" showCancunTours ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showCancunTours">
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/la-peninsula-de-yucatan':'https://www.mexicotoptours.com/the-yucatan-peninsula'"><span>{{'TOURS.YUCATAN_PENINSULA'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/maravillas-de-yucatan':'https://www.mexicotoptours.com/wonders-of-yucatan'"><span>{{'TOURS.WONDERS_OF_YUCATAN'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/el-mundo-maya':'https://www.mexicotoptours.com/the-mayan-world'"><span>{{'TOURS.THE_MAYAN_WORLD'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/aventura-maya':'https://www.mexicotoptours.com/mayan-adventure'"><span>{{'TOURS.MAYAN_ADVENTURE'
                      | translate}}</span></a></li>
              </ul>
            </li>
            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/circuitos-cdmx':'https://www.mexicotoptours.com/mexico-city'">{{'TOURS.MEXICO_CITY_TOURS'
                  | translate}}</a> <i (click)="showMexicoTours = !showMexicoTours"
                  [ngClass]=" showMexicoTours ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showMexicoTours">
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/el-camino-del-sur':'https://www.mexicotoptours.com/south-road'"><span>{{'TOURS.THE_SUN_ROAD'
                      |
                      translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/cdmx-peninsula-de-yucatan':'https://www.mexicotoptours.com/mexico-city-and-yucatan-peninsula'"><span>{{'TOURS.MEXICO_CITY_AND_YUCATAN_PENINSULA'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/mexico-breve':'https://www.mexicotoptours.com/brief-mexico'"><span>{{'TOURS.BRIEF_MEXICO'
                      |
                      translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/la-ruta-de-la-independencia':'https://www.mexicotoptours.com/the-independence-route'"><span>{{'TOURS.THE_ROUTE_OF_THE_INDEPENDENCE'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/contrastes-de-mexico':'https://www.mexicotoptours.com/mexico-contrast'"><span>{{'TOURS.MEXICO_CONTRASTS'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/mexico-clasico':'https://www.mexicotoptours.com/classic-mexico'"><span>{{'TOURS.CLASSIC_MEXICO'
                      | translate}}</span></a></li>
              </ul>
            </li>
          </ul>
        </div>
      </li>

      <li><a
          [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/traslados-contacto':'https://www.mexicotoptours.com/transfer-contact'"><span>{{'MENU.TRANSFERS'
            | translate}} </span></a></li>

      <li><a
          [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/contacto':'https://www.mexicotoptours.com/contact'"><span>{{'MENU.CONTACT_US'
            | translate}}</span></a></li>
    </ul>
  </div>
  <div class="follow">
    <h4 class="title">{{'FOLLOW_US' | translate}}</h4>
    <div class="btn">
      <a href="https://www.facebook.com/MexicoTopTours" target="_blank">
        <img src="assets/images/fb.png" alt="" width="20">
      </a>
      <a href="https://www.instagram.com/mexico_top_tours/" target="_blank">
        <img src="assets/images/instagram.png" alt="" width="20">
      </a>
      <a href="mailto:ventas@mexicotoptours.com" target="_blank">
        <img src="assets/images/icons/mail.png" alt="" width="20">
      </a>
      <a href="https://web.whatsapp.com/send?phone=9988458197" target="_blank">
        <img src="assets/images/icons/whatsapp.png" alt="" width="20">
      </a>
    </div>
  </div>
</div>

<div class="brand">
  <a class="privacy"
    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/privacy-and-data-protection-policy':'https://www.mexicotoptours.com/privacy-and-data-protection-policy'">{{'PRIVACY_TXT'
    | translate}}</a>
</div>


<mat-menu #tripList="matMenu">
  <div class="matmenulist">
    <nav>
      <ul>
        <li>
          <a mat-menu-item>
            <span class="mat-menu-txt">
              Cancun Private Trips
            </span>
          </a>
        </li>
        <li>
          <a mat-menu-item>
            <span class="mat-menu-txt">
              Cancun Regular Trips
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</mat-menu>
