<div class="footer">
  <div class="logo">
    <a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx':'https://www.mexicotoptours.com/'">
      <img src="assets/images/logo-b.webp" alt="">
    </a>
  </div>
  <div class="contact">
    <!-- <p class="title">{{'MENU.CONTACT_US' | translate}}</p> -->
    <h4 class="title">{{'CONTACT_US' | translate}}</h4>
    <!-- <a href="https://web.whatsapp.com/send?phone=529981956088" target="_blank"> -->
    <a href="tel:+52 998 195 6088" target="_blank">
      <p>+52 998 195 6088</p>
    </a>
    <!-- <a href="mailto:ventas@mexicotoptours.com" target="_blank">
      <p>ventas@mexicotoptours.com</p>
    </a> -->
    <p> {{'CONTACT_ADDRESS' | translate}}</p>
    <p>Cancún, México</p>
  </div>
  <div class="menu">
    <!-- <p class="title">{{'MENUTEXT' | translate}}</p> -->
    <h4 class="title"> {{'MENU.MENU' | translate}}</h4>
    <ul>
      <li><a [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx':'https://www.mexicotoptours.com/'"><span>{{'MENU.HOME'
            | translate}} </span></a></li>

      <li>
        <!-- <div class="submenu"><span>Trips<i [ngStyle]="{'transform': showToursSubmenu ? 'rotate(180deg)' : ''}" class="fa fa-chevron-down"></i></span> -->
        <div class="submenu"><span>
            <a style="color: inherit;"
              [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/excursiones-colectivas-cancun':'https://www.mexicotoptours.com/cancun-excursions'">
              {{'MENU.TRIP-FOOTER' | translate}}
            </a>
            <!-- <i (click)="showToursSubmenu = !showToursSubmenu" [ngClass]=" showToursSubmenu ? 'activeMenu' : ''"
              class="fa fa-chevron-down"></i> -->
          </span>
          <ul class="submenu-lvl1" *ngIf="showToursSubmenu">
            <li><a
                [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/excursiones-privado-cancun':'https://www.mexicotoptours.com/cancun-private-trips'"
                class="sub"><span>{{'TRIPS.CANCUN_PRIVATE_TRIPS' | translate}}</span></a></li>
            <li><a
                [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/excursiones-colectivo-cancun':'https://www.mexicotoptours.com/cancun-regular-trips'"
                class="sub"><span>{{'TRIPS.CANCUN_REGULAR_TRIPS' | translate}}</span></a></li>
          </ul>
        </div>
      </li>

      <li>
        <!-- <div class="submenu"><span (click)="showTripsSubmenu = !showTripsSubmenu">Tours <i [ngStyle]="{'transform': showTripsSubmenu ? 'rotate(180deg)' : ''}" class="fa fa-chevron-down"></i></span> -->
        <div class="submenu"><span><a style="color: inherit;"
              [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/circuitos':'https://www.mexicotoptours.com/tours'">{{'MENU.TOURS'
              | translate}}</a> <i (click)="showTripsSubmenu = !showTripsSubmenu"
              [ngClass]=" showTripsSubmenu ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></span>
          <ul class="submenu-lvl1" *ngIf="showTripsSubmenu">
            <!-- <li class="tour" (click)="showCancunTours = !showCancunTours"><span>Cancun Tours <i  [ngStyle]="{'transform': showCancunTours ? 'rotate(180deg)' : ''}" class="fa fa-chevron-down"></i></span> -->
            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/cancun-circuitos':'https://www.mexicotoptours.com/cancun-tours'">{{'TOURS.CANCUN_TOURS'
                  | translate}} </a> <i (click)="showCancunTours = !showCancunTours"
                  [ngClass]=" showCancunTours ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showCancunTours">
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/la-peninsula-de-yucatan':'https://www.mexicotoptours.com/the-yucatan-peninsula'"><span>{{'TOURS.YUCATAN_PENINSULA'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/maravillas-de-yucatan':'https://www.mexicotoptours.com/wonders-of-yucatan'"><span>{{'TOURS.WONDERS_OF_YUCATAN'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/el-mundo-maya':'https://www.mexicotoptours.com/the-mayan-world'"><span>{{'TOURS.THE_MAYAN_WORLD'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/aventura-maya':'https://www.mexicotoptours.com/mayan-adventure'"><span>{{'TOURS.MAYAN_ADVENTURE'
                      | translate}}</span></a></li>
              </ul>
            </li>
            <!-- <li class="tour" (click)="showMexicoTours = !showMexicoTours"><span>Mexico City Tours <i [ngStyle]="{'transform': showMexicoTours ? 'rotate(180deg)' : ''}" class="fa fa-chevron-down"></i></span> -->
            <li class="tour"><span><a style="color: inherit;"
                  [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/circuitos-cdmx':'https://www.mexicotoptours.com/mexico-city'">{{'TOURS.MEXICO_CITY_TOURS'
                  | translate}}</a> <i (click)="showMexicoTours = !showMexicoTours"
                  [ngClass]=" showMexicoTours ? 'activeMenu' : ''" class="fa fa-chevron-down"></i></span>
              <ul class="submenu-lvl2" *ngIf="showMexicoTours">
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/el-camino-del-sur':'https://www.mexicotoptours.com/south-road'"><span>{{'TOURS.THE_SUN_ROAD'
                      |
                      translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/cdmx-peninsula-de-yucatan':'https://www.mexicotoptours.com/mexico-city-and-yucatan-peninsula'"><span>{{'TOURS.MEXICO_CITY_AND_YUCATAN_PENINSULA'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/mexico-breve':'https://www.mexicotoptours.com/brief-mexico'"><span>{{'TOURS.BRIEF_MEXICO'
                      |
                      translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/la-ruta-de-la-independencia':'https://www.mexicotoptours.com/the-independence-route'"><span>{{'TOURS.THE_ROUTE_OF_THE_INDEPENDENCE'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/contrastes-de-mexico':'https://www.mexicotoptours.com/mexico-contrast'"><span>{{'TOURS.MEXICO_CONTRASTS'
                      | translate}}</span></a></li>
                <li><a
                    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/mexico-clasico':'https://www.mexicotoptours.com/classic-mexico'"><span>{{'TOURS.CLASSIC_MEXICO'
                      | translate}}</span></a></li>
              </ul>
            </li>
          </ul>
        </div>
      </li>

      <li><a
          [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/traslados-contacto':'https://www.mexicotoptours.com/transfer-contact'"><span>{{'MENU.TRANSFERS'
            | translate}} </span></a></li>

      <li><a
          [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/contacto':'https://www.mexicotoptours.com/contact'"><span>{{'MENU.CONTACT_US'
            | translate}}</span></a></li>
      <!-- <li><a href="https://www.mexicotoptours.com/"><span>Home</span></a></li>
            <li><a href="https://www.mexicotoptours.com/transfers"><span>{{'MENU.TRANSFERS' | translate}}</span></a></li>
            <li><a href="https://www.mexicotoptours.com/circuits"><span>{{'MENU.CIRCUITS' | translate}}</span></a></li>
            <li><a href="https://www.mexicotoptours.com/tours-prueba"><span>{{'MENU.TOURS' | translate}}</span></a></li>
            <li><a href="https://www.mexicotoptours.com/estancias-en-riviera-maya"><span>{{'MENU.EXPERIENCE' | translate}}</span></a></li>
            <li><a href="https://www.mexicotoptours.com/testimonies"><span>{{'MENU.TESTIMONIALS' | translate}}</span></a></li>
            <li><a href="https://www.mexicotoptours.com/about"><span>{{'MENU.ABOUT_US' | translate}}</span></a></li>
            <li><a href="https://www.mexicotoptours.com/contact"><span>{{'MENU.CONTACT_US' | translate}}</span></a></li>
            <li><a href="https://www.mexicotoptours.com/blogf208e0e1"><span>{{'MENU.BLOG' | translate}}</span></a></li>
            <li><a href="https://www.mexicotoptours.com/store"><span>{{'MENU.STORE' | translate}}</span></a></li>
            <li><a href="https://www.mexicotoptours.com/partners"><span>{{'MENU.PARTNERS' | translate}}</span></a></li>
            <li><a href="https://www.mexicotoptours.com/#"><span>{{'MENU.BROCHURES' | translate}}</span></a></li> -->
    </ul>
  </div>
  <div class="follow">
    <h4 class="title">{{'FOLLOW_US' | translate}}</h4>
    <div class="btn">
      <a href="https://www.facebook.com/MexicoTopTours" target="_blank">
        <img src="assets/images/fb.png" alt="" width="20">
      </a>
      <a href="https://www.instagram.com/mexico_top_tours/" target="_blank">
        <img src="assets/images/instagram.png" alt="" width="20">
      </a>
      <a href="mailto:ventas@mexicotoptours.com" target="_blank">
        <img src="assets/images/icons/mail.png" alt="" width="20">
      </a>
      <a href="https://web.whatsapp.com/send?phone=9988458197" target="_blank">
        <img src="assets/images/icons/whatsapp.png" alt="" width="20">
      </a>
    </div>
  </div>
</div>

<div class="brand">
  <a class="privacy"
    [href]="lang == 'es' ? 'https://www.mexicotoptours.com/es-mx/privacy-and-data-protection-policy':'https://www.mexicotoptours.com/privacy-and-data-protection-policy'">{{'PRIVACY_TXT'
    | translate}}</a>
</div>


<mat-menu #tripList="matMenu">
  <div class="matmenulist">
    <nav>
      <ul>
        <li>
          <a mat-menu-item>
            <span class="mat-menu-txt">
              Cancun Private Trips
            </span>
          </a>
        </li>
        <li>
          <a mat-menu-item>
            <span class="mat-menu-txt">
              Cancun Regular Trips
            </span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</mat-menu>
